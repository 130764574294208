import {
  capitalize,
  isEqual,
  isPlainObject,
  keys,
  map,
  omitBy,
  replace,
  some,
  transform,
} from 'lodash';

/* eslint-disable import/prefer-default-export */
export const normalizePhoneNumber = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}`;

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const copyTextToClipBoard = (text) => navigator.clipboard.writeText(text);

export const encodeB64 = (str) => {
  return window.btoa(encodeURIComponent(str));
};

export const decodeB64 = (str) => {
  return decodeURIComponent(window.atob(str));
};

export const formatName = (str) => {
  return map(str?.match(/[a-zA-Z]+[0-9]*|[0-9]+/g), capitalize).join(' ');
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const unMatchedFields = (orgData, modifiedData) =>
  keys(omitBy(orgData, (value, key) => isEqual(value, modifiedData[key])));

const toLowerCaseDeep = (obj) =>
  transform(
    obj,
    (accumulator, value, key) => {
      if (isPlainObject(value)) {
        accumulator[key] = toLowerCaseDeep(value);
      } else if (typeof value === 'string') {
        accumulator[key] = value.toLowerCase();
      } else {
        accumulator[key] = value;
      }
    },
    {}
  );

export const isDuplicatesFound = (collection, dataToValidate) =>
  some(collection, (item) => isEqual(toLowerCaseDeep(item), toLowerCaseDeep(dataToValidate)));

export const formatZipCode = (value) => {
  if (!value) return '';

  return replace(value, /[^0-9]/g, '')
    .match(/.{1,5}/g)
    ?.join('-');
};

export const transformZipCode = (zipCode) => {
  if (zipCode?.length === 9) {
    return `${zipCode.slice(0, 5)}-${zipCode.slice(5)}`;
  }

  return zipCode;
};
