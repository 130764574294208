/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getPartnersGql = gql`
  query getPartners {
    getPartners {
      partnerName
      partnerUuid
    }
  }
`;

export const getPartnerPracticesGql = gql`
  query getPartnerPractices($partnerId: UUID!) {
    getPartnerPractices(partnerId: $partnerId) {
      practiceId
      practiceName
      line1
      line2
      city
      state
      zip
    }
  }
`;

export const listPartnerProfilesGql = gql`
  query listPartners($limit: Int, $offset: Int, $filter: PartnerFilter, $sortBy: PartnerSortBy) {
    listPartners(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        partnerName
        partnerUuid
        partnerShortCode
        createdAt
        updatedAt
        hasApiAccess
        clientId
      }
    }
  }
`;

export const getPartnerWorkflowConfigGql = gql`
  query getLatestPartnerWorkflowConfig($partnerUUID: UUID!) {
    getLatestPartnerWorkflowConfig(partnerUUID: $partnerUUID) {
      partnerWorkflowConfigId
      workflowConfig {
        workflowConfigId
        config
      }
      enabled
    }
  }
`;

export const savePartnerWorkflowConfigGql = gql`
  mutation savePartnerWorkflowConfig($partnerUUID: UUID!, $input: WorkflowConfigInput) {
    savePartnerWorkflowConfig(partnerUUID: $partnerUUID, input: $input) {
      partnerWorkflowConfigId
    }
  }
`;

export const addPartnerWithApiAccessGql = gql`
  mutation addPartnerWithApiAccess($input: AddPartnerInput!) {
    addPartnerWithApiAccess(input: $input) {
      partnerUuid
      clientId
      clientSecret
    }
  }
`;
